import React from "react";
import styled from "styled-components";

import { BlocksContent, Button } from "@global";
import { Maybe, SanityStringWithBlockContent } from "@graphql-types";
import { Container } from "@util/standard";
import { colorsRGB, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  policy: Maybe<SanityStringWithBlockContent> | undefined;
}

const PolicyWrapper = styled(Container)`
  flex-direction: column;
  padding: 0 0 150px 12%;
  width: 80%;
  border-left: 1px solid ${colorsRGB.navy(0.4)};
  a {
    white-space: normal;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-left: none;
    padding: 0;
    margin: auto;
    margin-top: 20px;
  }
`;

const HeaderWrapper = styled(Container)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

function Policy({ policy }: Props) {
  if (policy == null) return null;

  const { title, link, TextContent } = policy;

  return (
    <PolicyWrapper>
      <HeaderWrapper justifyContent="space-between" width="100%">
        <h3>{title}</h3>
        {link && (
          <Button {...link} linkText={link.linkText ?? "Download Policy"} theme="borderedBlue" />
        )}
      </HeaderWrapper>
      {TextContent && (
        <div>
          <BlocksContent data={TextContent} />
        </div>
      )}
    </PolicyWrapper>
  );
}

export default Policy;
